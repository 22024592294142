import { Color, FC, Flex, spacer } from '@babushkaui/core';
import { MouseEventHandler } from 'react';
import { CSSObject, CSSProperties } from 'styled-components';

export const elevatedShadow: CSSProperties = {
  boxShadow: '0 12px 35px -5px #00000033, 0 -20px 25px -5px #FFFFFFCC',
};

interface Props {
  style?: CSSObject;
  flexDirection?: CSSProperties['flexDirection'];
  onClick?: MouseEventHandler<HTMLDivElement>;
  onLoad?: () => void;
}
export const ElevatedContainer: FC<Props> = ({ children, style, flexDirection, onClick }) => {
  return (
    <Flex
      style={{
        flexDirection: flexDirection ?? 'column',
        padding: spacer(4),
        marginLeft: spacer(8),
        marginRight: spacer(8),
        marginBottom: spacer(16),
        borderRadius: spacer(8),
        textAlign: 'center',
        backgroundColor: Color.Base,
        justifyContent: 'space-between',
        ...elevatedShadow,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </Flex>
  );
};
