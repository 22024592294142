import React__default from 'react';

const Loading = ({ width, height }) => (React__default.createElement("div", { style: {
        width: width ?? 40,
        height: height ?? width ?? 40,
    } },
    React__default.createElement("svg", { viewBox: '0 0 40 40' },
        React__default.createElement("path", { opacity: '0.2', fill: 'currentColor', d: 'M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946\n    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634\n    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z' }),
        React__default.createElement("path", { fill: 'currentColor', d: 'M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0\n    C22.32,8.481,24.301,9.057,26.013,10.047z' },
            React__default.createElement("animateTransform", { attributeType: 'xml', attributeName: 'transform', type: 'rotate', from: '0 20 20', to: '360 20 20', dur: '0.8s', repeatCount: 'indefinite' })))));

export { Loading };
