import { Color, spacer } from '../../style/color.js';

({
    overflowY: 'scroll',
    overflowX: 'hidden',
    /* width */
    '::-webkit-scrollbar': {
        width: 4,
        height: '20px',
        borderRadius: '',
    },
    '::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
        background: Color.Neutral.N400,
        borderRadius: spacer(),
    },
    '::-webkit-scrollbar-hover': {
        background: '#0f0',
    },
    '::-webkit-scrollbar-track-piece:start': {
        marginTop: spacer(8),
    },
    '::-webkit-scrollbar-track-piece:end': {
        marginBottom: spacer(8),
    },
});
/**
 * @public modifies CSS to provide a slim scrollbar. Must be used within a styeld-component to ensure cross-browser compatibility
 * @param offsetStart for Curved upper border Radius' specify the start offset
 * @param offsetEnd for Curved bottom border Radius' specify the start offset
 * @returns
 */
const scrollbarProvider = (offsetStart = 8, offsetEnd) => ({
    overflowY: 'scroll',
    overflowX: 'hidden',
    /* width */
    '::-webkit-scrollbar': {
        width: 4,
        height: '20px',
        borderRadius: '',
    },
    '::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
        background: Color.Neutral.N400,
        borderRadius: spacer(),
    },
    '::-webkit-scrollbar-hover': {
        background: '#0f0',
    },
    '::-webkit-scrollbar-track-piece:start': {
        marginTop: spacer(offsetStart),
    },
    '::-webkit-scrollbar-track-piece:end': {
        marginBottom: spacer(offsetEnd ?? offsetStart),
    },
});

export { scrollbarProvider };
