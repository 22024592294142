import { isNil } from '../../../../../typedash/dist/index.js';
import React__default, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Transitions } from '../../../style/animation.js';
import { BODY_TYPEFACE } from '../../../typography/index.js';
import '../../../icons/types.js';
import { Loading } from '../../../icons/Loading.js';
import '../../../icons/LoadingPulse.js';
import { Color, spacer, vhSpace } from '../../../style/color.js';
import '../../basic/index.js';
import { Flex } from '../../basic/wrappers.js';

const commonProperties = {
    // marginTop: spacer(4),
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    border: 0,
    borderRadius: spacer(4),
    padding: vhSpace(3, 4),
    fontSize: '1.1rem',
    fontFamily: BODY_TYPEFACE,
    fontWeight: 300,
    transition: Transitions.UI,
};
const ButtonRoot = styled.button({
    ...commonProperties,
    color: Color.Base,
    backgroundColor: Color.Primary.N400,
    ':disabled': {
        backgroundColor: '#999999',
    },
    ':enabled:active': {
        backgroundColor: Color.Primary.N800,
    },
});
const SmallRoot = styled.button({
    ...commonProperties,
    color: Color.Base,
    backgroundColor: Color.Primary.N400,
    fontSize: '.8rem',
    ':disabled': {
        backgroundColor: '#999999',
    },
    ':enabled:active': {
        backgroundColor: Color.Primary.N800,
    },
});
const SecondaryRoot = styled.button({
    ...commonProperties,
    color: Color.Primary.N400,
    border: `1px solid ${Color.Primary.N400}`,
    borderRadius: spacer(4),
    backgroundColor: 'white',
    ':disabled': {
        color: '#999999',
        borderColor: '#999999',
    },
});
const rootComponent = {
    primary: ButtonRoot,
    secondary: SecondaryRoot,
    small: SmallRoot,
};
const Button = ({ children, loading = false, theme = 'primary', onClick, ...pass }) => {
    const [calling, setCalling] = useState(false);
    const Root = rootComponent[theme];
    const clicked = useCallback((e) => {
        if (isNil(onClick))
            return;
        setCalling(true);
        try {
            onClick(e);
        }
        finally {
            setCalling(false);
        }
    }, [onClick]);
    const isLoading = calling || loading;
    return (React__default.createElement(Root, { onClick: clicked, ...pass },
        React__default.createElement(Flex, { style: {
                flex: 1,
                display: isLoading ? 'grid' : 'none',
                position: 'absolute',
                inset: 0,
                justifyContent: 'center',
                alignItems: 'center',
            } },
            React__default.createElement(Loading, null)),
        React__default.createElement("div", { style: { opacity: isLoading ? 0 : 1 } }, children)));
};
const SecondaryButton = ({ children, loading = false, ...pass }) => {
    return (React__default.createElement(SecondaryRoot, { ...pass },
        React__default.createElement(Flex, { style: {
                display: loading ? 'initial' : 'none',
                position: 'absolute',
                left: spacer(4),
                right: spacer(4),
                justifyContent: 'center',
            } },
            React__default.createElement(Loading, null)),
        React__default.createElement("div", { style: { opacity: loading ? 0 : 1 } }, children)));
};

export { Button, SecondaryButton };
