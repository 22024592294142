import { Col, Color, FC, Grid, IconLength, Row, spacer, Tooltip, vSpace } from '@babushkaui/core';
import React, { PropsWithChildren, useContext } from 'react';
import styled, { CSSObject } from 'styled-components';

interface ItemProps {
  selected: boolean;
}

export const ItemContainer = styled(Grid)((props: ItemProps) => ({
  placeItems: 'center',
  cursor: 'pointer',
  color: Color.Primary.N400,
  borderRadius: spacer(7),
  padding: spacer(2),
  margin: spacer(),
  background: props.selected ? 'white' : 'unset',
  ':hover': {
    background: 'white',
  },
  transition: '400ms ease-in-out',
}));

const PageContext = React.createContext('/provider');

interface MenuProps {
  href?: string;
  toolTip?: string;
}

export const MenuLink: FC<PropsWithChildren<MenuProps>> = ({ href, children, toolTip }) => {
  const context = useContext(PageContext);

  return (
    <Row style={{ alignItems: 'center', position: 'relative' }}>
      <a href={href ?? '#'}>
        <Tooltip placement='right' title={toolTip ?? ''}>
          <ItemContainer selected={href === context}>{children}</ItemContainer>
        </Tooltip>
      </a>
    </Row>
  );
};

export const MenuItem: FC<PropsWithChildren<MenuProps>> = ({ href, children }) => {
  const context = useContext(PageContext);

  return (
    <Row style={{ alignItems: 'center', position: 'relative' }}>
      <ItemContainer selected={href === context}>{children}</ItemContainer>
    </Row>
  );
};

const MenuRoot = styled(Col)({
  position: 'relative',
  margin: spacer(6),
  borderRadius: spacer(80),
  alignItems: 'center',
  backgroundColor: Color.Primary.A100,
  border: `1px solid ${Color.Primary.A400}`,
  justifyContent: 'space-between',
  // zIndex: 1,
});

/**
 * there are issues stacking items with a blur backgrop on top of each other. So the menu, itself is not
 * blurred, but this background is, and then we just set it behind the menu.
 */
const Background = styled.div({
  position: 'absolute',
  inset: 0,
  borderRadius: spacer(7),
  zIndex: -1,
  backdropFilter: 'blur(4px)',
});

export const Menu: FC<PropsWithChildren<{ route: string; style?: CSSObject }>> = ({ route, children, style }) => {
  return (
    <MenuRoot style={style}>
      <Background />
      <PageContext.Provider value={route}>{children}</PageContext.Provider>
    </MenuRoot>
  );
};

export const Content = styled(Col)({
  display: 'relative',
  justifyContent: 'flex-start',
  flex: 1,
  paddingRight: spacer(6),
  margin: vSpace(6),
});

export const Logo: FC<{ size?: number }> = ({ size, style } = { size: IconLength.N100 }) => (
  // eslint-disable-next-line @next/next/no-img-element
  <img src='/logo.svg' width={size ?? IconLength.N200} height={size ?? IconLength.N200} alt='logo' style={style} />
);

/** Invisible menu behind the static one for padding */
export const Padding = () => (
  <Menu route='' style={{ opacity: '0', flexShrink: 0 }}>
    <MenuLink>
      <Logo />
    </MenuLink>
  </Menu>
);
