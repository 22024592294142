import {
  Button,
  Col,
  Color,
  FC,
  Flex,
  FormCol,
  FormRow,
  Input,
  Row,
  spacer,
  Title,
  TransitionInterval,
} from '@babushkaui/core';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoginResponse } from '~/auth/types';
import { ElevatedContainer } from '~/components/ElevatedContainer';
import { Logo } from '~/components/layout/Menu';
import { webLogger } from '~/webClients/webLogging';

const Root = styled.div({
  display: 'grid',
  minHeight: '100vh',
  alignItems: 'center',
});

function useQueryString() {
  const router = useRouter();

  return router.query;
}

const ProviderLogin: NextPage = () => {
  const [loading, setLoading] = useState(false);
  const [email, setFirst] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setError] = useState(false);
  const { returnTo } = useQueryString();
  const submit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      setError(false);
      try {
        setLoading(true);

        const res = await fetch('/api/login/provider', {
          method: 'POST',
          body: JSON.stringify({
            email,
            password,
            returnTo,
          }),
        });

        if (res.status !== 200) {
          setError(true);
          return;
        }

        const { redirect }: LoginResponse = await res.json();

        // router does not send cookies which we just authc with.
        window.location.assign(redirect);
      } catch (error) {
        webLogger.warn('Error Logging in', {}, error as Error);
      } finally {
        setLoading(false);
      }
    },
    [email, password, returnTo],
  );

  return (
    <Root>
      <FormRow style={{ justifyContent: 'center' }}>
        <FadeIn>
          <ElevatedContainer
            style={{
              minWidth: 300,
              maxWidth: 500,
              alginItems: 'stretch',
              justifyContent: 'center',
            }}
          >
            <Col
              style={{
                flex: 1,
                flexBasis: 500,
                justifyContent: 'center',
              }}
            >
              <Row style={{ justifyContent: 'center' }}>
                <Logo size={spacer(20)} />
              </Row>
              <Title
                style={{
                  textAlign: 'center',
                  paddingBottom: spacer(4),
                }}
              >
                Login
              </Title>
              <form onSubmit={submit}>
                <FormCol>
                  <Input name='email' placeholder='email' value={email} onChange={(e) => setFirst(e.target.value)} />
                  <Input
                    name='password'
                    placeholder='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                  />
                  <Row
                    id='incorrect-login'
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      textAlign: 'center',
                      display: loginError ? 'initial' : 'none',
                      marginBottom: spacer(4),
                    }}
                  >
                    <b style={{ color: Color.Red.N400 }}>Incorrect Login, please try again</b>
                  </Row>
                  <Row>
                    <Button type='submit' id='verify' loading={loading}>
                      Login
                    </Button>
                  </Row>
                  <Row style={{ justifyContent: 'center' }}>
                    <div>
                      <b>
                        <a style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }} href='login/reset'>
                          Forgot Password?
                        </a>
                      </b>
                    </div>
                  </Row>
                </FormCol>
              </form>
            </Col>
          </ElevatedContainer>
        </FadeIn>
      </FormRow>
    </Root>
  );
};

export const FadeIn: FC = ({ children, style }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => setOpacity(1), []);

  return (
    <Flex
      style={{
        opacity,
        transition: TransitionInterval.STANDARD,
        transform: `translateY(${opacity === 0 ? 50 : 0}px)`,
        ...style,
      }}
    >
      {children}
    </Flex>
  );
};

export default ProviderLogin;
