import { datadogLogs } from '@datadog/browser-logs';

const testLogger = datadogLogs.createLogger('test');
testLogger.setHandler('console');

const getLogger = () => {
  if (process.env.NODE_ENV === 'test') {
    return testLogger;
  }

  return datadogLogs.logger;
};

export const webLogger = getLogger();
